import React from 'react';
import { Link } from 'react-router-dom';

function MyLink({ onClick, to, children, color }) {
    return (
        <Link
            onClick={onClick}
            to={to}
            className={color != null ? color + '-color' : ''}
            style={{ fontWeight: 'bolder' }}
        >
            {children}
        </Link>
    );
}

export default MyLink;
