import React, { useEffect } from 'react';
import { authService } from 'fbase';
import { dbService } from './../../fbase';
import { useHistory } from 'react-router-dom';
import Processing from './Processing';

function Authentication({ userObj }) {
    let params = new URLSearchParams(window.location.search);
    let apiKey = params.get('apiKey');
    let actionCode = params.get('oobCode');
    let mode = params.get('mode');
    let history = useHistory();
    console.log('statrted');
    // useEffect(() => {
    console.log('Auth_useEffect');
    console.log(process.env.REACT_APP_API_KEY);
    console.log(apiKey);
    console.log(actionCode);
    console.log(params);
    useEffect(() => {
        if (apiKey === process.env.REACT_APP_API_KEY) {
            console.log(params.toString());
            console.log(actionCode);
            authService
                .checkActionCode(actionCode)
                .then(async (info) => {
                    console.log('info');
                    console.log(info);
                    authService
                        .applyActionCode(actionCode)
                        .then(async (res) => {
                            alert('인증에 성공하였습니다!');
                            window.close();
                        })
                        .catch((e) => console.log(e));
                })
                .catch(function (error) {
                    console.log(error);
                    // Code is invalid or expired. Ask the user to verify their email address
                    // again.
                });
        }

        return () => {};
    }, [actionCode]);

    //     return () => {};
    // }, []);

    return <Processing />;
}

export default Authentication;
