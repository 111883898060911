import VerticalSpacing from 'components/Atom/VerticalSpacing';
import React, { useEffect, useState } from 'react';

import { dbService } from '../../fbase';
import DiaryItem from './../Molecules/DiaryItem';

function DiariesPage({ userObj }) {
    const [diaries, setDiaries] = useState([]);
    const getDiaries = () => {
        dbService
            .collection('diaries')
            .where('ownerId', '==', userObj.uid)
            .orderBy('diaryIdx', 'asc')
            .get()
            .then((qss) => {
                if (!qss.empty) {
                    let arr = [];
                    qss.forEach((doc) => {
                        arr = [...arr, doc.data()];
                    });
                    setDiaries(arr);
                }
            });
    };
    useEffect(() => {
        getDiaries();
        return () => {
            setDiaries([]);
        };
    }, []);

    return (
        <div className="diaries-page">
            <h2 className="head-title">일기장</h2>
            <VerticalSpacing height={30} />
            {diaries.map((diaryObj) => (
                <DiaryItem key={diaryObj.diaryIdx} diaryObj={diaryObj} />
            ))}
        </div>
    );
}

export default DiariesPage;
