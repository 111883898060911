import React, { forwardRef } from 'react';

const InputBox = forwardRef(
    (
        {
            onBlur,
            onChange,
            name,
            type,
            width,
            value,
            size,
            placeholder,
            required,
        },
        ref,
    ) => (
        <input
            onBlur={onBlur}
            onChange={onChange}
            name={name == null ? '' : name}
            type={type == null ? 'text' : type}
            value={value == null ? '' : value}
            ref={ref}
            required={required ? true : false}
            className={
                'inputbox ' +
                (width == null ? 'default-width' : width + '-width') +
                ' ' +
                (size == null ? 'default-size' : size + '-size')
            }
            placeholder={placeholder}
        />
    ),
);

export default InputBox;
