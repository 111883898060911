import React from 'react';
import { authService } from 'fbase';

function SignOut({ setInitState, setAppUser, setUserObj }) {
    authService.signOut();
    setInitState(0);
    setAppUser(null);
    setUserObj(null);

    return <div></div>;
}

export default SignOut;
