import React, { useState } from 'react';

import {
    // BrowserRouter,
    HashRouter as Router,
    Redirect,
    Route,
    Switch,
    useLocation,
} from 'react-router-dom';
import Auth from 'components/Auth';
import FullScreen from 'components/routes/FullScreen';
import MainScreen from 'components/routes/MainScreen';
import LoginPage from './Page/LoginPage';
import HomePage from './Page/HomePage';
import DiaryPage from './Page/DiaryPage';
import TopicPage from './Page/TopicPage';
import BookmarkPage from './Page/BookmarkPage';
import CommunityPage from './Page/CommunityPage';
import SettingPage from './Page/SettingPage';
import Processing from './Page/Processing';
import RecordPage from './Page/RecordPage';
import InitPage from './Page/InitPage';
import Authentication from './Page/Authentication';
import SignOut from './Page/SignOut';
import DiariesPage from './Page/DiariesPage';
import JournalPage from './Page/JournalPage';

const AppRouter = ({
    isLoggedIn,
    userObj,
    setUserObj,
    initUser,
    appUser,
    setAppUser,
}) => {
    const [initState, setInitState] = useState(0);
    const [authPersistence, setAuthPersistence] = useState(false);

    const fullScreens = ['record'];
    const mainScreens = [
        'home',
        'diary',
        'topic',
        'bookmark',
        'community',
        'setting',
    ];
    const location = useLocation();

    return (
        // <Router>
        <>
            {/* <span id="initState">{initState}</span>
            <br />
            <span id="initState">{userObj ? userObj.uid : '미로그인'}</span> */}
            {/* <p>{initState}</p> */}
            <Route path="/signout">
                <SignOut
                    setInitState={setInitState}
                    setUserObj={setUserObj}
                    setAppUser={setAppUser}
                />
            </Route>
            {isLoggedIn ? (
                !initUser ? (
                    <Switch>
                        {/* @@ 여긴언제타는거지?? */}
                        <Route
                            path="/record/:diaryIdx"
                            component={(props) => (
                                <RecordPage userObj={userObj} {...props} />
                            )}
                        />
                        <Route path="/authentication">
                            <Authentication
                                setInitState={setInitState}
                                appUser={appUser}
                                setAppUser={setAppUser}
                                userObj={userObj}
                            />
                        </Route>

                        <FullScreen>
                            <Route exact path="/">
                                <InitPage
                                    appUser={appUser}
                                    setAppUser={setAppUser}
                                    initState={initState}
                                    setInitState={setInitState}
                                    userObj={userObj}
                                    authPersistence={authPersistence}
                                />
                            </Route>
                            <Route
                                path="/record/:diaryIdx"
                                component={(props) => (
                                    <RecordPage userObj={userObj} {...props} />
                                )}
                            />
                            <Redirect to="/" />
                        </FullScreen>
                    </Switch>
                ) : (
                    <Switch>
                        {fullScreens.indexOf(location.pathname.split('/')[1]) >
                            -1 && (
                            <FullScreen>
                                <Switch>
                                    <Route
                                        path="/record/:diaryIdx"
                                        component={(props) => (
                                            <RecordPage
                                                userObj={userObj}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Redirect to="/" />
                                </Switch>
                            </FullScreen>
                        )}
                        {mainScreens.indexOf(location.pathname.split('/')[1]) >
                            -1 && (
                            <MainScreen appUser={appUser} userObj={userObj}>
                                <Switch>
                                    <Route
                                        path="/home"
                                        component={(props) => (
                                            <HomePage
                                                appUser={appUser}
                                                userObj={userObj}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/diary/:idx"
                                        component={(props) => (
                                            <DiaryPage
                                                userObj={userObj}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/diary/:idx/:journalIdx"
                                        component={(props) => (
                                            <JournalPage
                                                userObj={userObj}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/diary"
                                        component={(props) => (
                                            <DiariesPage
                                                userObj={userObj}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/topic"
                                        component={TopicPage}
                                    />
                                    <Route
                                        path="/bookmark"
                                        component={BookmarkPage}
                                    />
                                    <Route
                                        path="/community"
                                        component={CommunityPage}
                                    />
                                    <Route
                                        path="/setting"
                                        component={SettingPage}
                                    />
                                    <Redirect to="/" />
                                </Switch>
                            </MainScreen>
                        )}
                        <Redirect to="/home" />
                    </Switch>
                )
            ) : (
                <Switch>
                    <Route path="/authentication">
                        <Authentication
                            setInitState={setInitState}
                            appUser={appUser}
                            setAppUser={setAppUser}
                            userObj={userObj}
                        />
                    </Route>
                    <Route exact path="/">
                        <LoginPage
                            setAppUser={setAppUser}
                            setInitState={setInitState}
                            authPersistence={authPersistence}
                            setAuthPersistence={setAuthPersistence}
                        />
                    </Route>

                    <Redirect to="/" />
                </Switch>
            )}
        </>
        // </Router>
    );
};
export default AppRouter;
