import React, { useEffect, useState } from 'react';
import MiddleContainer from './../Atom/MiddleContainer';
import VerticalSpacing from '../Atom/VerticalSpacing';
import Paragraph from './../Atom/Paragraph';
import Header from './../Atom/Header';
import Button from './../Atom/Button';
import InputBox from './../Atom/InputBox';
import { authService, firebaseInstance } from 'fbase';

import HorizontalSpacing from 'components/Atom/HorizontalSpacing';
import GoogleSignIn from './../Atom/GoogleSignIn';
import Align from './../Atom/Align';
import Span from './../Atom/Span';
import { Link } from 'react-router-dom';
import MyLink from './../Atom/MyLink';
import FacebookSignIn from 'components/Atom/FacebookSignIn';
import HorizontalRule from './../Atom/HorizontalRule';
import { useTransition, animated } from 'react-spring';

function LoginPage({
    setAppUser,
    setInitState,
    authPersistence,
    setAuthPersistence,
}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [email2, setEmail2] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordCheck2, setPasswordCheck2] = useState('');
    const [error, setError] = useState('');
    const [error2, setError2] = useState('');

    const [mode, setMode] = useState('signIn');

    const onChange = (event) => {
        const {
            target: { name, value },
        } = event;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'email2') {
            setEmail2(value);
        } else if (name === 'password2') {
            setPassword2(value);
        } else if (name === 'passwordCheck2') {
            setPasswordCheck2(value);
        } else if (name === 'authPersistence') {
            setAuthPersistence(event.target.checked);
            if (event.target.checked)
                setError(
                    '자동 로그인 기능은 반드시\n안전한 환경에서만 사용해주세요!',
                );
        }
    };
    const getErrorMsg = (code) => {
        //         auth/email-already-in-use
        // Thrown if there already exists an account with the given email address.
        // auth/invalid-email
        // Thrown if the email address is not valid.
        // auth/operation-not-allowed
        // Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
        // auth/weak-password
        // Thrown if the password is not strong enough.
        switch (code) {
            case 'auth/email-already-in-use':
                return '이미 사용중인 이메일입니다.';

            case 'auth/invalid-email':
                return '이메일의 형태가 유효하지 않습니다.';

            case 'auth/operation-not-allowed':
                return '허용되지 않은 접근입니다. 관리자에게 문의하세요.';

            case 'auth/weak-password':
                return '더 안전한 비밀번호로 입력해주세요.';

            case 'auth/user-disabled':
                return '비활성화된 계정입니다. 관리자에게 문의하세요.';

            case 'auth/user-not-found':
                return '이메일 또는 비밀번호를 다시 확인해주세요.';

            case 'auth/wrong-password':
                return '이메일 또는 비밀번호를 다시 확인해주세요.';

            case 'my-auth/password-check':
                return '입력하신 두 개의 비밀번호가 일치하지 않습니다.';
            default:
                return '오류가 발생하였습니다. (' + code + ')';
        }
    };
    const signUp = async () => {
        if (password2 !== passwordCheck2) {
            setError2(getErrorMsg('my-auth/password-check'));
            return;
        }

        await authService
            .createUserWithEmailAndPassword(email2, password2)
            .then((user) => {})
            .catch((error) => {
                setError2(getErrorMsg(error.code));
            });
    };
    const changeMode = () => {
        console.log(mode);
        if (mode === 'signIn') setMode('signUp');
        else if (mode === 'signUp') setMode('signIn');
    };
    const forgotPassword = async () => {
        console.log(1);
        var emailAddress = prompt('메일주소를 입력해주세요.');
        authService
            .sendPasswordResetEmail(emailAddress)
            .then(function () {
                alert('메일이 발송되었습니다!');
            })
            .catch(function (error) {
                alert('오류가 발생하였습니다! 잠시후 다시 시도해주세요.');
            });
    };
    const defaultSignin = async () => {
        try {
            //SESSION 일반
            //LOCAL 유지

            const persistence = authPersistence
                ? firebaseInstance.auth.Auth.Persistence.LOCAL
                : firebaseInstance.auth.Auth.Persistence.SESSION;

            await authService.setPersistence(persistence).then(() => {
                return authService.signInWithEmailAndPassword(email, password);
            });
        } catch (error) {
            console.log(error);
            setError(getErrorMsg(error.code));
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (mode === 'signUp') {
            signUp();
        } else if (mode === 'signIn') {
            defaultSignin();
        }
    };
    useEffect(() => {
        setAppUser(null);
        setInitState(0);
        return () => {};
    }, []);
    return (
        <div style={{ height: '100%', backgroundColor: '#40718666' }}>
            <MiddleContainer>
                <div
                    style={{
                        margin: '0 auto',
                        backgroundColor: 'white',
                        display: 'inline-block',
                        padding: 50,
                        width: 380,
                        boxShadow: '5px 5px 10px #666666',
                    }}
                >
                    <Header size="big">DayLog</Header>
                    <VerticalSpacing height={40} />

                    {mode === 'signIn' ? (
                        <>
                            <form onSubmit={onSubmit}>
                                <InputBox
                                    onChange={onChange}
                                    name="email"
                                    type="email"
                                    value={email}
                                    placeholder="이메일"
                                    width="full"
                                    required
                                />
                                <VerticalSpacing height={15} />
                                <InputBox
                                    onChange={onChange}
                                    name="password"
                                    type="password"
                                    value={password}
                                    placeholder="비밀번호"
                                    width="full"
                                    required
                                />
                                <VerticalSpacing height={10} />
                                <Align align="left">
                                    <label title="반드시 안전한 환경에서만 사용해주세요!">
                                        <input
                                            type="checkbox"
                                            name="authPersistence"
                                            onChange={onChange}
                                            checked={authPersistence}
                                        />
                                        <Span size="small">
                                            &nbsp;자동 로그인
                                        </Span>
                                    </label>
                                </Align>

                                <VerticalSpacing height={15} />
                                <Button
                                    onClick={defaultSignin}
                                    weight="400"
                                    width="full"
                                    color="primary"
                                >
                                    로그인
                                </Button>
                                <input
                                    type="submit"
                                    style={{ display: 'none' }}
                                />
                                <VerticalSpacing height={5} />
                                <Paragraph size="small" color="alert">
                                    {error}{' '}
                                </Paragraph>
                                {/* <VerticalSpacing height={10} /> */}
                                <HorizontalRule />
                                <VerticalSpacing height={20} />
                                <Align align="center">
                                    <GoogleSignIn
                                        authPersistence={authPersistence}
                                    />
                                </Align>
                                <VerticalSpacing height={10} />
                                <Align align="center">
                                    <FacebookSignIn
                                        authPersistence={authPersistence}
                                    />
                                </Align>
                            </form>
                        </>
                    ) : (
                        <>
                            <form onSubmit={onSubmit}>
                                <InputBox
                                    onChange={onChange}
                                    name="email2"
                                    type="email"
                                    value={email2}
                                    placeholder="이메일"
                                    width="full"
                                    required
                                />
                                <VerticalSpacing height={15} />
                                <InputBox
                                    onChange={onChange}
                                    name="password2"
                                    type="password"
                                    value={password2}
                                    placeholder="비밀번호"
                                    width="full"
                                    required
                                />
                                <VerticalSpacing height={15} />
                                <InputBox
                                    onChange={onChange}
                                    name="passwordCheck2"
                                    type="password"
                                    value={passwordCheck2}
                                    placeholder="비밀번호 확인"
                                    width="full"
                                    required
                                />
                                <VerticalSpacing height={25} />
                                <Button
                                    onClick={signUp}
                                    weight="400"
                                    width="full"
                                    color="primary"
                                >
                                    가입하기
                                </Button>
                                <input
                                    type="submit"
                                    style={{ display: 'none' }}
                                />
                                <VerticalSpacing height={5} />
                                <Paragraph size="small" color="alert">
                                    {error2}{' '}
                                </Paragraph>
                                {/* <VerticalSpacing height={10} /> */}
                                <HorizontalRule />
                                <VerticalSpacing height={20} />
                            </form>
                        </>
                    )}

                    <VerticalSpacing height={15} />
                    {mode === 'signIn' ? (
                        <Paragraph size="small">
                            아직 계정이 없다면?{' '}
                            <MyLink to="#" color="primary" onClick={changeMode}>
                                가입하기
                            </MyLink>
                        </Paragraph>
                    ) : (
                        <Paragraph size="small">
                            계정이 생각났다면?{' '}
                            <MyLink to="#" color="primary" onClick={changeMode}>
                                로그인하기
                            </MyLink>
                        </Paragraph>
                    )}
                    <VerticalSpacing height={5} />
                    <Paragraph size="small">
                        <MyLink to="" color="primary" onClick={forgotPassword}>
                            비밀번호를 잊으셨나요?
                        </MyLink>
                    </Paragraph>
                </div>
            </MiddleContainer>
        </div>
    );
}

export default LoginPage;

//what if 구글에서는 wnsrnek3@gmail.com 라는아이디로썼고 깃헙에서는 wnsrnek3@gmail.com라는걸로썼으면? 그리고 그 둘이 다르다면?
