import React, { useState } from 'react';

function MenuNavButton({
    name,
    icon,
    expendable,
    childrenButtons,
    expanded,
    setExpanded,
    onClick,
}) {
    const onFlipperClick = () => {
        setExpanded(!expanded);
    };
    return expendable ? (
        <>
            <div
                className={
                    'menu-nav-button ' + (expendable ? ' expendable' : '')
                }
                onClick={onClick}
            >
                <div className="menu-nav-button__img-container">
                    <i className={'fas fa-' + icon}></i>
                </div>
                <div className="menu-nav-button__text">{name}</div>
                {expendable && (
                    <div className="flipper-area" onClick={onFlipperClick}>
                        <i
                            className={`fas fa-chevron-${
                                expanded ? 'up' : 'down'
                            } flipper`}
                        ></i>
                    </div>
                )}
            </div>
        </>
    ) : (
        <>
            <div className={'menu-nav-button '}>
                <div className="menu-nav-button__img-container">
                    <i className={'fas fa-' + icon}></i>
                </div>
                <div className="menu-nav-button__text">{name}</div>
                {expendable && <i className="fas fa-chevron-down flipper"></i>}
            </div>
        </>
    );
}

export default MenuNavButton;
