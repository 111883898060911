import React, { useState } from 'react';
import { authService } from 'fbase';
import { firebaseInstance } from 'fbase';

function Auth() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newAccount, setNewAccount] = useState(true);
    const [error, setError] = useState('');
    const onChange = (event) => {
        const {
            target: { name, value },
        } = event;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };
    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            let data;
            if (newAccount) {
                data = await authService.createUserWithEmailAndPassword(
                    email,
                    password,
                );
            } else {
                data = await authService.signInWithEmailAndPassword(
                    email,
                    password,
                );
            }
            console.log(data);
        } catch (error) {
            setError(error.message);
            // console.error(error);
        }
    };
    const toggleAccount = () => {
        setNewAccount((prev) => !prev);
    };
    const onSocialClick = async (event) => {
        // const { name } = event.target;
        let provider;
        // if (name === 'google') {
        provider = new firebaseInstance.auth.GoogleAuthProvider();
        // }
        const data = await authService.signInWithPopup(provider);
        console.log(data);
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                <input
                    onChange={onChange}
                    name="email"
                    type="email"
                    placeholder="email"
                    value={email}
                    required
                />
                <input
                    onChange={onChange}
                    name="password"
                    type="password"
                    placeholder="password"
                    value={password}
                    required
                />
                <input
                    type="submit"
                    value={newAccount ? 'Create Account' : 'Log In'}
                />
                <p>{error}</p>
                <span onClick={toggleAccount}>
                    {newAccount ? 'Login' : 'create account'}
                </span>
            </form>
            <div>
                <button onClick={onSocialClick} name="google">
                    continue with google
                </button>
            </div>
        </div>
    );
}

export default Auth;
