import React from 'react';
// import { Spring, animated } from 'react-spring';
import { Spring } from 'react-spring/renderprops';

function Loading({ display }) {
    const style = ({ props }) => ({
        border: '10px solid #666',
        borderRadius: '100%',
        height: props.diameter,
        left: '50%',
        opacity: props.opacity,
        position: 'absolute',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        width: props.diameter,
        zIndex: 1,
        display: display == null ? null : display,
        // reset: true,
    });

    return (
        <Spring
            reset
            from={{ opacity: 1, diameter: 0 }}
            to={{ opacity: 0, diameter: 100 }}
        >
            {(props) => <div style={style({ props })} />}
        </Spring>
    );
}

export default Loading;
