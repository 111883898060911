import React from 'react';

function SettingPage() {
    return (
        <>
            <h2 className="head-title">설정</h2>

            <div>
                <h2>유튜브 연동/해제</h2>
                <div className="topic-box">주제1</div>
                <div className="topic-box">주제2</div>
                <div className="topic-box">주제3</div>
            </div>
        </>
    );
}

export default SettingPage;
