import React, { useEffect, useState } from 'react';
import { authService } from 'fbase';
import { useHistory } from 'react-router-dom';

function Profile({ appUser }) {
    // console.log(appUser);
    const history = useHistory();
    const signOut = () => {
        history.push('/signout');
    };
    return (
        <div className="profile">
            <div className="img profile__img"></div>
            <div className="profile__text-area">
                <p className="profile__name">
                    {appUser != null ? appUser.nickname : ''}
                </p>
                <p className="profile__number-of-journals">
                    일기 수 <span>32</span>
                </p>
                <p className="profile__number-of-tags">
                    태그 수 <span>174</span>
                </p>
            </div>
            <br />
            <button onClick={signOut}>logout</button>
        </div>
    );
}

export default Profile;
