import React from 'react';

function Icon({ name, iconStyle, style }) {
    return (
        <i
            className={
                'no-drag ' +
                'fa' +
                (iconStyle === 'solid' ? 's' : 'r') +
                ' fa-' +
                name
            }
            style={style}
        ></i>
    );
}

export default Icon;
