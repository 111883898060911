import React, { useEffect, useRef } from 'react';

function Webcam({ videoObj }) {
    useEffect(() => {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({ video: true })
                .then(function (stream) {
                    videoObj.current.srcObject = stream;
                })
                .catch(function (err0r) {
                    console.log('Something went wrong!');
                });
        }
        return () => {};
    }, []);
    return <video autoPlay id="record-video" ref={videoObj}></video>;
}

export default Webcam;
