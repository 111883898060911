import Icon from 'components/Atom/Icon';
import React from 'react';
import { Link } from 'react-router-dom';

function DiaryItem({ diaryObj }) {
    // console.log(journalObj.title);
    return (
        <Link to={'/diary/' + diaryObj.diaryIdx}>
            <div className="diary-item">
                <div className="diary-item__image">
                    <Icon
                        name="book-open"
                        iconStyle="solid"
                        style={{ color: 'white' }}
                    />
                </div>
                <h4 className="diary-item__title">
                    {diaryObj.name === '' ? '제목 없음' : diaryObj.name}{' '}
                </h4>
            </div>
        </Link>
    );
}

export default DiaryItem;
