import React from 'react';

function Button({ weight, onClick, children, size, color, width, disabled }) {
    //가운데라는 의미가 아니고 중간크기의 버튼임.
    return (
        <div
            onClick={onClick}
            className={
                'button ' +
                (size == null ? 'default-size' : size + '-size') +
                ' ' +
                (color == null ? 'default-color' : color + '-color') +
                ' ' +
                (width == null ? 'default-width' : width + '-width') +
                ' ' +
                (disabled ? 'disabled' : '')
            }
            style={{ fontWeight: weight }}
        >
            {children}
        </div>
    );
}

export default Button;
