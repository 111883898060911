import VerticalSpacing from 'components/Atom/VerticalSpacing';
import React from 'react';
import DiariesPage from './DiariesPage';

function HomePage({ appUser, userObj }) {
    return (
        <div className="home-page">
            <h2 className="head-title">홈</h2>
            <p className="celebrate-message">
                {appUser.nickname}님, 환영합니다!
            </p>
            <div className="recent-issues">
                <h2 className="recent-issues__title">오늘의 토픽</h2>
                <p className="recent-issue">
                    <ul>
                        <li>감정적으로 대처하지 말아야하는 순간들</li>{' '}
                        <li>나의 단점을 보완하기 위한 노력</li>{' '}
                        <li>거절하기 어려운 부탁을 받았을 때</li>
                    </ul>
                    <br />
                </p>
            </div>
            <div className="advertisement">
                <img
                    src="https://lh3.googleusercontent.com/tbq3ID0VBO1uHNjKo2MKBXYqHswSdR2bPTb5xImcp95KKfTCVtrxCW6s008ZC1UAcxCMgtXN9rMuRJZh_BQnkSnCMthS9QR4axTptXM=w298-h170-p-nu-pa"
                    style={{ width: 300, height: 120 }}
                />
            </div>

            <VerticalSpacing height={40} />
            <div className="recent-issues">
                <h2 className="recent-issues__title">최근 이슈</h2>
                <p className="recent-issue">
                    <ul>
                        <li>
                            구름이 가린 '붉은 달'···누리꾼들 "아쉬워···다음 번
                            기약"{' '}
                        </li>{' '}
                        <li>
                            올림픽 후원사 아사히 “올림픽 취소 결단하라” 총리에
                            촉구
                        </li>
                    </ul>
                    <br />
                </p>
            </div>
        </div>
    );
}

export default HomePage;
