import React from 'react';

function BookmarkPage() {
    return (
        <>
            <h2 className="head-title">저장됨</h2>

            <div>
                <h2>이 주제에 대해서는 어떻게 생각하시나요?</h2>
                <div className="topic-box">주제1</div>
                <div className="topic-box">주제2</div>
                <div className="topic-box">주제3</div>
            </div>

            <div>
                <h2>자기 전에 생각해보면 좋을 만한 것들</h2>
                <div className="topic-box">주제1</div>
                <div className="topic-box">주제2</div>
                <div className="topic-box">주제3</div>
            </div>
        </>
    );
}

export default BookmarkPage;
