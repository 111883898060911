import React from 'react';

function Paragraph({ children, size, color }) {
    return (
        <p
            className={
                'paragraph ' +
                (size == null ? 'default-size' : size + '-size') +
                ' ' +
                (color == null ? 'default-color' : color + '-color')
            }
        >
            {children}
        </p>
    );
}

export default Paragraph;
