import React from 'react';
import { authService, firebaseInstance } from './../../fbase';
import HorizontalSpacing from './HorizontalSpacing';

function GoogleSignIn() {
    const onClick = async (authPersistence, event) => {
        const persistence = authPersistence
            ? firebaseInstance.auth.Auth.Persistence.LOCAL
            : firebaseInstance.auth.Auth.Persistence.SESSION;
        // 일단 무의미한짓임. 추후 변경 고려해보자. @@@
        await authService.setPersistence(persistence).then(async () => {
            const provider = new firebaseInstance.auth.GoogleAuthProvider();
            await authService
                .signInWithPopup(provider)
                .then((t) => {
                    console.log(t);
                })
                .catch((e) => {
                    console.log(e);
                    // ('auth/account-exists-with-different-credential');
                });
        });
    };
    return (
        <div
            style={{
                cursor: 'pointer',
                width: '100%',
                // backgroundColor: '#4285f4',
                backgroundColor: '#ea4335ee',
                color: 'white',
            }}
            className="button default-size full-width "
            onClick={onClick}
        >
            <span className="fa fa-google"></span>
            <HorizontalSpacing width={10} />
            Google로 계속하기
        </div>
    );
}

export default GoogleSignIn;
