import React from 'react';

function CommunityPage() {
    return (
        <>
            <h2 className="head-title">커뮤니티</h2>

            <div>
                <h2>이런의견도 있었어요!</h2>
                <div className="topic-box">좋아요 댓글</div>
                <div className="topic-box">주제2</div>
                <div className="topic-box">주제3</div>
            </div>
        </>
    );
}

export default CommunityPage;
