import React from 'react';
import { useHistory } from 'react-router-dom';

function LogoutButtonX() {
    let history = useHistory();
    const onClick = () => {
        history.push('/signout');
    };
    return (
        <div
            style={{
                borderRadius: '50%',
                color: 'white',
                backgroundColor: 'grey',
                width: 38,
                height: 38,
                fontWeight: 'bolder',
                fontSize: '18pt',
                fontFamilt: '',
                position: 'absolute',
                top: 30,
                left: 30,
                cursor: 'pointer',
            }}
        >
            <i
                className={'fas fa-times'}
                onClick={onClick}
                title="로그아웃"
            ></i>
        </div>
    );
}

export default LogoutButtonX;
