import logo from './logo.svg';
import './App.css';
import MainScreen from 'components/routes/MainScreen';
import FullScreen from 'components/routes/FullScreen';
import { Route } from 'react-router-dom';
import AppRouter from 'components/AppRouter';
import { useEffect, useState } from 'react';
import { authService } from 'fbase';
import Processing from './components/Page/Processing';
import { dbService } from './fbase';

function App() {
    const [initApp, setInitApp] = useState(false);
    const [userObj, setUserObj] = useState({ uid: '' });
    const [initUser, setInitUser] = useState(false);

    const [appUser, setAppUser] = useState();

    useEffect(() => {
        authService.onAuthStateChanged(async (user) => {
            if (user) {
                console.log('로긴첨!');
                setUserObj({
                    email: user.email,
                    uid: user.uid,
                    displayName: user.displayName,
                    emailVerified: user.emailVerified,
                });
                await dbService
                    .collection('users')
                    .where('uid', '==', user.uid)
                    .get()
                    .then((qss) => {
                        if (qss.size > 0) {
                            console.log(qss.docs[0].data());
                            setInitUser(qss.docs[0].data().isInitialized);
                            setAppUser({
                                ...qss.docs[0].data(),
                                id: qss.docs[0].id,
                            });
                        }
                    });
                const checkInterval = setInterval(() => {
                    // console.log(authService.currentUser);
                    // console.log(authService.currentUser.emailVerified);
                    if (authService.currentUser) {
                        authService.currentUser.reload();
                        if (authService.currentUser.emailVerified) {
                            setUserObj({
                                email: user.email,
                                uid: user.uid,
                                displayName: user.displayName,
                                emailVerified: user.emailVerified,
                            });
                            clearInterval(checkInterval);
                        }
                    }
                }, 2000);
            } else {
                setUserObj(null);
            }

            setInitApp(true);
        });
        return () => {};
    }, []);
    return (
        <div className="container">
            {initApp ? (
                <AppRouter
                    isLoggedIn={Boolean(userObj)}
                    setUserObj={setUserObj}
                    userObj={userObj}
                    initUser={initUser}
                    appUser={appUser}
                    setAppUser={setAppUser}
                />
            ) : (
                <Processing />
            )}
        </div>
    );
}

export default App;
