import React, { useEffect, useState } from 'react';
import JournalsGroup from './JournalsGroup';

function JournalsGroups({ journals }) {
    const [journalsGroups, setJournalsGroups] = useState(null);

    // forEach(j in journals){
    //     console.log(new Date(j.createdAt));
    // }
    useEffect(() => {
        const data = journals.reduce((acc, j) => {
            const key =
                new Date(j.createdAt).getFullYear() +
                '-' +
                (new Date(j.createdAt).getMonth() + 1);

            acc[key] = acc[key] || [];
            acc[key].push(j);
            return acc;
        }, {});
        setJournalsGroups(data);
        return () => {
            setJournalsGroups([]);
        };
    }, [journals]);

    return (
        <>
            {journalsGroups != null &&
                Object.keys(journalsGroups).map((key, idx) => (
                    <JournalsGroup
                        key={key}
                        yyyymm={key}
                        journals={journalsGroups[key]}
                    />
                ))}
        </>
    );
}

export default JournalsGroups;
