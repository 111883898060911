import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { dbService } from '../../fbase';
import MenuNavButton from '../Atom/MenuNavButton';

function DiaryNavButton({ userObj }) {
    const location = useLocation();

    const [diaries, setDiaries] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const onClick = () => {
        if (!expanded) {
            setExpanded(true);
        }
    };
    useEffect(() => {
        if (userObj) {
            if (window.location.pathname.indexOf('/diary/') > -1)
                setExpanded(true);
            dbService
                .collection('diaries')
                .where('ownerId', '==', userObj.uid)
                .orderBy('diaryIdx')
                .get()
                .then((qss) => {
                    setDiaries(
                        qss.docs.map((doc) => ({
                            key: doc.id,
                            name: doc.data().name,
                            icon: doc.data().icon,
                            diaryIdx: parseInt(doc.data().diaryIdx),
                        })),
                    );
                })
                .catch((e) => {
                    console.error(e);
                });
            return () => {
                //setExpanded(false);
            };
        }
    }, [userObj]);

    return (
        <>
            <NavLink to="/diary">
                <MenuNavButton
                    name="일기장"
                    icon="book-open"
                    expendable
                    expanded={expanded}
                    setExpanded={setExpanded}
                    onClick={onClick}
                />
            </NavLink>
            {expanded &&
                // location.pathname.indexOf('/diary') > -1 &&
                diaries.map((d) => (
                    <NavLink to={'/diary/' + d.diaryIdx} key={d.key}>
                        <div
                            className={`menu-nav-button__children ${
                                location.pathname.indexOf('/diary') > -1 &&
                                ' menu-nav-button__children--diary-mode'
                            }`}
                        >
                            <MenuNavButton
                                name={d.name}
                                icon={d.icon == null ? 'book-open' : d.icon}
                            />
                        </div>
                    </NavLink>
                ))}
        </>
    );
}

export default DiaryNavButton;
