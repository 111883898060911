import React, { Children, useRef, useState } from 'react';
import { Link, NavLink, Route } from 'react-router-dom';

import BookmarkPage from '../Page/BookmarkPage';
import DiaryPage from '../Page/DiaryPage';
import HomePage from '../Page/HomePage';
import TopicPage from '../Page/TopicPage';
import CommunityPage from '../Page/CommunityPage';
import SettingPage from '../Page/SettingPage';
import MenuNavButton from '../Atom/MenuNavButton';
import Profile from '../Molecules/Profile';
import LoginPage from '../Page/LoginPage';
import Processing from './../Page/Processing';
import DiaryNavButton from './../Organism/DiaryNavButton';

function MainScreen({ appUser, children, userObj }) {
    const [componentLoaded, setComponentLoaded] = useState(false);
    return (
        <>
            <div className="nav-container">
                <Profile appUser={appUser} />

                <NavLink to="/home">
                    <MenuNavButton name="홈" icon="home" />
                </NavLink>

                <DiaryNavButton
                    name="일기장"
                    icon="book-open"
                    userObj={userObj}
                />

                <NavLink to="/topic">
                    <MenuNavButton name="토픽/주제" icon="comments" />
                </NavLink>
                {/* <NavLink to="/community">
                    <MenuNavButton name="커뮤니티" icon="users" />
                </NavLink> */}
                {/* <NavLink to="/bookmark">
                    <MenuNavButton name="저장됨" icon="bookmark" />
                </NavLink> */}
                <NavLink to="/setting">
                    <MenuNavButton name="설정" icon="cog" />
                </NavLink>
            </div>

            <div className="main-container">
                {/* <LoadComponent
                    componentLoaded={componentLoaded}
                    setComponentLoaded={setComponentLoaded}
                > */}
                {children}
                {/* </LoadComponent> */}
            </div>
        </>
    );
}

export default MainScreen;
