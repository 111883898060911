import Loading from 'components/Atom/Loading';
import React, { useEffect, useState } from 'react';

import { dbService } from '../../fbase';
import JournalsGroups from '../Molecules/JournalsGroups';

function DiaryPage({ userObj, match, setComponentLoaded }) {
    const [journals, setJournals] = useState([]);
    const [diary, setDiary] = useState({ name: 'Loading...' });
    const [refJournal, setRefJournal] = useState('');
    const [loaded1, setLoaded1] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    // const loadMore = useRef();

    const getDiaryInfo = () => {
        dbService
            .collection('diaries')
            .where('ownerId', '==', userObj.uid)
            .where('diaryIdx', '==', parseInt(match.params.idx))
            .get()
            .then((qss) => {
                setDiary(qss.docs[0].data());
                // setComponentLoaded(true);
                setLoaded1(true);
            });
    };
    const getRecentJournals = () => {
        dbService
            .collection('journals')
            .where('ownerId', '==', userObj.uid)
            .where('diaryIdx', '==', parseInt(match.params.idx))
            .orderBy('createdAt', 'desc')
            .limit(20)
            // .get()
            // .then((qss) => { //@@
            //     const journalArray = qss.docs.map((doc) => ({
            //         id: doc.id,
            //         ...doc.data(),
            //     }));
            //     setJournals(journalArray);
            //     setRefJournal(qss.docs[qss.docs.length - 1]);
            // })
            // .catch((err) => console.log(err));
            .onSnapshot((qss) => {
                const journalArray = qss.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setJournals(journalArray);
                setRefJournal(qss.docs[qss.docs.length - 1]);
                setLoaded2(true);
            });
    };

    const getMoreJournals = () => {
        dbService
            .collection('journals')
            .where('ownerId', '==', userObj.uid)
            .where('diaryIdx', '==', parseInt(match.params.idx))
            .orderBy('createdAt', 'desc')
            .startAfter(refJournal)
            .limit(20)
            .get()
            .then((qss) => {
                if (!qss.empty) {
                    const journalArray = qss.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setJournals(journals.concat(journalArray));
                    setRefJournal(qss.docs[qss.docs.length - 1]);
                } else {
                    console.log('끝입니다');
                    console.log(journals);
                }
            })
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        //일부 개수또는 구간까지만 끊어서 받고 다음거는 스크롤하여 로드하기 또는 클릭하여 로드하기로 페이징하여 받아와야함.
        // groupJournalsByMonth();

        getDiaryInfo(); // 현재 다이어리 정보 세트
        getRecentJournals(); //가장 최근 1달의 일기내용 가져오기

        return () => {
            // setJournals([]);
            // setComponentLoaded(false);
        };
    }, []);

    return loaded1 && loaded2 ? (
        <div className="diary-page">
            <h2 className="head-title">{diary.name}</h2>

            <div className="view-modes-container">
                <div className="view-mode active">시간 순 보기</div>
                <div className="view-mode ">태그 모아보기</div>
            </div>

            <JournalsGroups journals={journals} />
            <div onClick={getMoreJournals}> 더 보기</div>
            {/* <Link to="/record"> 설정 모드에 따라 팝업형또는 종속형으로 띄우기 */}
            <a href={'/record/' + match.params.idx} target="_blank">
                <div id="write-button">+</div>
            </a>
            {/* </Link> */}
        </div>
    ) : (
        <Loading />
    );
}

export default DiaryPage;
