import React from 'react';

function Header({ children, size, color, style }) {
    return (
        <h1
            className={
                'header ' +
                (size == null ? 'default-size' : size + '-size') +
                ' ' +
                (color == null ? 'default-color' : color + '-color') +
                ' '
            }
            style={style}
        >
            {children}
        </h1>
    );
}

export default Header;
