import React from 'react';
import JournalItem from './JournalItem';

function JournalsGroup({ yyyymm, journals }) {
    const getMonthString = (yyyymm) => {
        const y = yyyymm.split('-')[0];
        let m = yyyymm.split('-')[1];
        if (m.substr(0, 1) === '0') {
            m = m.substr(1, 1);
        }
        if (y === '' + new Date().getFullYear()) return m + '월';
        else return y + '년 ' + m + '월';
    };

    return (
        <div className="">
            <h4 className="journals-group__title">{getMonthString(yyyymm)}</h4>
            {journals.map((journalObj) => (
                <JournalItem key={journalObj.id} journalObj={journalObj} />
                // var isOwner = journalObj.creatorId === userObj.uid;
                //dbService.doc(`journals/${journalObj.id}`).delete();
                // const journals = await dbService.collection("journals").where("createorId",'==',userObj.uid).orderby("createdAt","desc")get();

                //props, state로 통일하면은 한쪽에서바꾼게 다른데에서도 자동으로 바뀌어 렌더링될것임. 그렇지않고 매번 authService.~~할수도있지만 이러면 업뎃이 바로 안되겠찌??
                //근데 그object가 너무크면 리액트가 판단을못함 => 1.필요한것만뽑아서 객체만들기 2. Object.assign({},user)을 set하면됨. 새롭게 만들어서 넣는느낌.
            ))}
        </div>
    );
}

export default JournalsGroup;
