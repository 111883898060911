import React, { useState, useEffect, useRef } from 'react';
import RecordRTC from 'recordrtc';
import { v4 as uuidv4 } from 'uuid';
import { captureUserMedia } from '../../AppUtils';
import axios from 'axios';
import WebCam from '../Atom/Webcam';
import { dbService, storageService } from './../../fbase';
import Moveable from 'react-moveable';
import Icon from 'components/Atom/Icon';
import HorizontalRule from 'components/Atom/HorizontalRule';
import Loading from 'components/Atom/Loading';
import Processing from './Processing';

/**
 * 녹화시작 전이면 화면 검게 흐리게 보이게하다가
 * 녹화시작되면 검은거 제거
 * 해당내용은  state로 제어
 * 녹화시작하면 녹화되고있는 시간이 상단우측에 표시되며 빨간색 동그란 이미지가 뜨도록.
 * 영상녹화안해도 일기는 쓸수있게하자.
 * 녹화시작/ 등록하는 UI정하기 @@
 */
function RecordPage({ userObj, match }) {
    const [title, setTitle] = useState('');
    const [descHTML, setDescHTML] = useState('');
    const [descText, setDescText] = useState('');
    const [recordVideo, setRecordVideo] = useState(null);

    const [draggable, setDraggable] = useState(false);
    const [mouseClicked, setMouseClicked] = useState(false);

    const [recording, setRecording] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [thumbnail, setThumbnail] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [uploadProgressRate, setUploadProgressRate] = useState(0);
    const titleObj = useRef();
    const descObj = useRef();
    const canvasObj = useRef();
    const videoObj = useRef();

    const getFormatDate = (d) => {
        var date = new Date(d);
        var year = date.getFullYear(); //yyyy
        var month = 1 + date.getMonth(); //M
        // month = month >= 10 ? month : '0' + month; //month 두자리로 저장
        var day = date.getDate(); //d
        // day = day >= 10 ? day : '0' + day; //day 두자리로 저장
        return year + '년 ' + month + '월 ' + day + '일'; //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
    };
    const setChangeListener = (div, listener) => {
        div.addEventListener('blur', listener);
        // div.addEventListener('keyup', listener);
        div.addEventListener('paste', listener);
        div.addEventListener('copy', listener);
        div.addEventListener('cut', listener);
        // div.addEventListener('delete', listener);
        div.addEventListener('mouseup', listener);
    };
    const onChange = (event) => {
        if (event.target.id === 'record__title')
            setTitle(event.target.innerText);
        else if (event.target.id === 'record__desc') {
            setDescHTML(event.target.innerHTML);
            setDescText(event.target.innerText);
        }
    };

    const hasGetUserMedia = !!(
        (
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia
        ) /*||
        navigator.mediaDevices.getUserMedia*/
    );
    // var recordVideo = null;
    var stream = null;
    const webCamObject = useRef();

    const requestUserMedia = async () => {
        stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        const rv = await new RecordRTC(stream, { type: 'video' });
        setLoaded(true);
        setRecordVideo(rv);
    };
    const startRecord = () => {
        console.log('start in 1 sec');
        setTimeout(() => {
            canvasObj.current.width = videoObj.current.videoWidth;
            canvasObj.current.height = videoObj.current.videoHeight;
            let ctx = canvasObj.current.getContext('2d');
            // ctx.save();
            ctx.scale(-1, 1);
            ctx.drawImage(
                videoObj.current,
                0,
                0,
                canvasObj.current.width * -1,
                canvasObj.current.height,
            );

            setThumbnail(canvasObj.current.toDataURL('image/png'));

            recordVideo.startRecording();
            setRecording(true);
        }, 1000);
    };
    const stopRecord = async () => {
        setUploading(true);

        recordVideo.stopRecording(async () => {
            var blob = recordVideo.getBlob();
            var uploadTask = storageService
                .ref()
                .child(`${userObj.uid}/${uuidv4()}.webm`)
                .put(blob);

            uploadTask.on(
                'state_changed',
                function (snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    setUploadProgressRate(progress);
                    switch (snapshot.state) {
                        case 'paused': // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case 'running': // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                    }
                },
                function (error) {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            console.log(error);
                            // User doesn't have permission to access the object
                            break;

                        case 'storage/canceled':
                            console.log(error);
                            // User canceled the upload
                            break;

                        case 'storage/unknown':
                            console.log(error);
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                },
                function () {
                    // Upload completed successfully, now we can get the download URL
                    console.log(uploadTask.snapshot.ref);
                    uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then(async (videoUrl) => {
                            console.log('File available at', videoUrl);
                            dbService
                                .collection('journals')
                                .where('ownerId', '==', userObj.uid)
                                .where('diaryIdx', '==', 1) //@@@
                                .orderBy('journalIdx', 'desc')
                                .limit(1)
                                .get()
                                .then((qss) => {
                                    let journalIdx = 1;
                                    if (qss.docs.length > 0) {
                                        journalIdx =
                                            parseInt(
                                                qss.docs[0].data().journalIdx,
                                            ) + 1;
                                    }
                                    dbService
                                        .collection('journals')
                                        .add({
                                            ownerId: userObj.uid,
                                            createdAt: Date.now(),
                                            modifiedAt: Date.now(),
                                            title,
                                            descHTML,
                                            descText,
                                            videoUrl,
                                            diaryIdx: parseInt(
                                                match.params.diaryIdx,
                                            ),
                                            journalIdx,
                                            thumbnail,
                                        })
                                        .then(() => {
                                            alert('작성 완료!');
                                            window.close();
                                        });

                                    // setComponentLoaded(true);
                                });
                        });
                },
            );
        });
    };
    useEffect(() => {
        /* if (!hasGetUserMedia) {
            alert(
                'Your browser cannot stream from your webcam. Please switch to Chrome or Firefox.',
            );
            // return;
        }*/
        requestUserMedia();

        setChangeListener(titleObj.current, onChange);
        setChangeListener(descObj.current, onChange);
        setTitle(getFormatDate(Date.now()) + '의 일기');

        return () => {
            console.log('컴포넌트가 화면에서 사라짐');
        };
    }, []);

    const containerRef = useRef();
    const [frame, setFrame] = React.useState({
        translate: [0, 0],
        rotate: 0,
        transformOrigin: '50% 50%',
    });
    const onMoveBarMouseOver = (e) => {
        setDraggable(true);
    };
    const onMoveBarMouseDown = (e) => {
        setMouseClicked(true);
    };
    const onMoveBarMouseUp = (e) => {
        setMouseClicked(false);
        setDraggable(false);
    };
    const onMoveBarMouseLeave = (e) => {
        if (!mouseClicked) {
            setDraggable(false);
        }
    };
    return (
        <>
            <div
                style={{
                    width: `${uploadProgressRate}vw`,
                    height: 8,
                    backgroundColor: '#0091fd',
                    transition: '0.5s ease-in-out',
                    opacity: `${uploadProgressRate > 0 ? 1 : 0}`,
                    position: 'fixed',
                    top: 0,
                    zIndex: '10',
                }}
            ></div>
            <Moveable
                target={containerRef}
                resizable={true}
                keepRatio={false}
                throttleResize={0}
                // renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
                renderDirections={['nw', 'ne', 'sw', 'se']}
                pinchThreshold={20}
                // renderDirections={[]}
                // edge={true}
                className={'moveable'}
                zoom={2.5}
                origin={false}
                padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                onResizeStart={(e) => {
                    e.setOrigin(['%', '%']);
                    e.dragStart && e.dragStart.set(frame.translate);
                }}
                onResize={(e) => {
                    const beforeTranslate = e.drag.beforeTranslate;

                    frame.translate = beforeTranslate;
                    e.target.style.width = `${e.width}px`;
                    e.target.style.height = `${e.height}px`;
                    e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
                }}
                // originDraggable={true}
                // originRelative={true}
                draggable={draggable}
                throttleDrag={0}
                onDragStart={(e) => {
                    e.set(frame.translate);
                }}
                onDrag={(e) => {
                    frame.translate = e.beforeTranslate;
                }}
                onDragEnd={({ target, isDrag, clientX, clientY }) => {
                    /*if (
                        clientX < 0 ||
                        clientY < 0 ||
                        target.style.left < 0 ||
                        target.style.top < 0
                    ) {
                        console.log(123);
                        target.style.transform = 'translate(  0)';
                        target.style.left = 0;
                        target.style.top = 0;
                    }*/
                }}
                bounds={{ left: 0, top: 0 }}
                onRender={(e) => {
                    const { translate, rotate, transformOrigin } = frame;
                    e.target.style.transformOrigin = transformOrigin;
                    e.target.style.transform =
                        `translate(${translate[0]}px, ${translate[1]}px)` +
                        ` rotate(${rotate}deg)`;
                }}
            />
            <Processing display={loaded ? 'none' : null} />
            {/* 얘는 걍 분기로하면 다른처리가 안돼서 이렇게조치함 */}
            <div
                className="record-container"
                onMouseUp={onMoveBarMouseUp}
                style={!loaded ? { display: 'none' } : {}}
            >
                <div className="record-edit-box" ref={containerRef}>
                    <div
                        className="record-edit-box__move-bar"
                        onMouseOver={onMoveBarMouseOver}
                        onMouseDown={onMoveBarMouseDown}
                        onMouseLeave={onMoveBarMouseLeave}
                    >
                        <Icon
                            name="bars"
                            iconStyle="solid"
                            style={{ color: 'white', opacity: 0.65 }}
                        />
                        {/* <HorizontalRule /> */}
                    </div>
                    <h2
                        className="record-edit-box__title"
                        contentEditable
                        suppressContentEditableWarning
                        ref={titleObj}
                        id="record__title"
                    >
                        {title}
                    </h2>
                    <div
                        className="record-edit-box__desc "
                        contentEditable
                        suppressContentEditableWarning
                        ref={descObj}
                        id="record__desc"
                    >
                        {/* {descText + ''} */}
                    </div>
                    <div
                        className="record-edit-box__button--regi btn"
                        onClick={!recording ? startRecord : stopRecord}
                    >
                        {!recording ? '녹화 시작' : '녹화 종료'}
                    </div>
                    &nbsp;&nbsp;
                </div>
                <WebCam videoObj={videoObj} />
                <canvas ref={canvasObj} style={{ display: 'none' }}></canvas>
                <p>{stream}</p>
            </div>
        </>
    );
}

export default RecordPage;

// const [state, setState] = useState({
//     // recordVideo: null,
//     uploading: false,
//     stream: null,
// });
// const { uploading, stream } = state;
