import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import MiddleContainer from '../Atom/MiddleContainer';
import VerticalSpacing from '../Atom/VerticalSpacing';
import Button from '../Atom/Button';
import Paragraph from '../Atom/Paragraph';
import Bold from '../Atom/Bold';
import Inputbox from '../Atom/InputBox';
import HorizontalSpacing from '../Atom/HorizontalSpacing';
import { Redirect } from 'react-router-dom';
import Header from '../Atom/Header';
import { authService, dbService, firebaseInstance } from './../../fbase';
import LogoutButtonX from './../Atom/LogoutButtonX';
import Icon from './../Atom/Icon';
// import { v4 as uuidv4 } from 'uuid';

function InitPage({
    appUser,
    setAppUser,
    initState,
    setInitState,
    userObj,
    authPersistence,
}) {
    const [userName, setUserName] = useState('');
    const [diaryName, setDiaryName] = useState('');
    const props = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: {
            duration: 1000,
        },
    });
    const props2 = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: {
            duration: 1000,
        },
    });

    const inputRef = useRef();

    const actionCodeSettings = {
        url: 'https://daylog.kr/authentication',
        handleCodeInApp: true,
    };
    const cl = (msg) => console.log(msg);

    const checkInitState = async () => {
        if (initState === 0) {
            if (userObj.emailVerified) {
                // auth완료된상태라면
                await dbService
                    .collection('users')
                    .where('uid', '==', userObj.uid)
                    .get()
                    .then(async (querySnapshot) => {
                        // 인증만된애라면 디비인서트
                        if (querySnapshot.size === 0) {
                            const userInitObj = {
                                uid: userObj.uid,
                                email: userObj.email,
                                authorizedAt: Date.now(),
                                isInitialized: false,
                                nickname: '',
                            };
                            await dbService
                                .collection('users')
                                .add(userInitObj)
                                .then((docRef) => {
                                    console.log(docRef);
                                    console.log(docRef.id);
                                    setAppUser({
                                        ...userInitObj,
                                        id: docRef.id,
                                    });
                                    setInitState(3);
                                });
                        } else if (querySnapshot.size > 0) {
                            // 인증도됐고 이미 인서트된애
                            let tempAppUser = querySnapshot.docs[0].data();
                            tempAppUser = {
                                ...tempAppUser,
                                id: querySnapshot.docs[0].id,
                            };
                            console.log(tempAppUser);
                            setAppUser(tempAppUser);
                            if (tempAppUser.isInitialized) {
                                setInitState(100);
                            } else if (!tempAppUser.isInitialized) {
                                setInitState(3);
                            }
                        }
                    });
            } else {
                setInitState(1);
            }
        } else if (initState === 2) {
            if (userObj.emailVerified) {
                await dbService
                    .collection('users')
                    .where('uid', '==', userObj.uid)
                    .get()
                    .then(async (querySnapshot) => {
                        // 인증만된애라면 디비인서트
                        if (querySnapshot.size === 0) {
                            const userInitObj = {
                                uid: userObj.uid,
                                email: userObj.email,
                                authorizedAt: Date.now(),
                                isInitialized: false,
                                nickname: '',
                            };
                            await dbService
                                .collection('users')
                                .add(userInitObj)
                                .then((docRef) => {
                                    console.log(docRef);
                                    console.log(docRef.id);
                                    setAppUser({
                                        ...userInitObj,
                                        id: docRef.id,
                                    });
                                    setInitState(3);
                                });
                        } else if (querySnapshot.size > 0) {
                            // 인증도됐고 이미 인서트된애
                            let tempAppUser = querySnapshot.docs[0].data();
                            tempAppUser = {
                                ...tempAppUser,
                                id: querySnapshot.docs[0].id,
                            };
                            setAppUser(tempAppUser);
                            if (tempAppUser.isInitialized) {
                                setInitState(100);
                            } else if (!tempAppUser.isInitialized) {
                                setInitState(3);
                            }
                        }
                    });
            }
        }
        if (initState === 3) {
            // 닉네임 설정 단계
            if (appUser.nickname !== '') {
                // 닉네임 이미 설정 완료
                setInitState(4);
            } else {
                // 닉네임 설정 필요
                if (userName === '') setUserName(userObj.displayName);
                if (inputRef.current != null) {
                    inputRef.current.focus();
                }
            }
        } else if (initState === 4) {
            // 다이어리 생성 단계
            await dbService
                .collection('diaries')
                .where('ownerId', '==', userObj.uid)
                .get()
                .then((qrySnapshot) => {
                    if (qrySnapshot.size === 0) {
                        // 다이어리 생성 필요
                        if (inputRef.current != null) {
                            inputRef.current.focus();
                        }
                    } else {
                        // 다이어리 이미 생성 완료
                        setInitState(10);
                    }
                });
        } else if (initState === 10) {
            await dbService.doc(`users/${appUser.id}`).update({
                isInitialized: true,
            });
            setInitState(100);
            //완료
        }
    };
    const sendEmail = async () => {
        //인증메일 전송 단계
        await authService;
        await authService.currentUser
            .sendEmailVerification(actionCodeSettings)
            .then((snap) => {
                console.log('메일전송 완료');
            })
            .catch((err) => console.log(err));

        setInitState(2);
    };
    const confirmUserName = async (event) => {
        event.preventDefault();
        var pattern = /[~!@#$%^&*()_+|<>?:{}]/;
        if (pattern.test(userName.trim())) {
            alert('사용할 수 없는 특수문자가 포함되어 있습니다.');
            return;
        } else if (userName.trim() === '' || userName.trim() == null) {
            alert('이름을 올바르게 입력해 주세요.');
            return;
        } else {
            await dbService.doc(`users/${appUser.id}`).update({
                nickname: userName,
            });
            setAppUser({ ...appUser, nickname: userName });
        }
        setInitState(4);
    };
    const confirmDiaryName = async (event) => {
        event.preventDefault();
        if (diaryName.trim() === '' || diaryName.trim() == null) {
            alert('이름을 올바르게 입력해 주세요.');
            return;
        }
        dbService
            .collection('diaries')
            .where('ownerId', '==', appUser.uid)
            .get()
            .then(async (qss) => {
                await dbService
                    .collection('diaries')
                    .add({
                        ownerId: appUser.uid,
                        createdAt: Date.now(),
                        name: diaryName,
                        diaryIdx: qss.size + 1,
                    })
                    .then(() => {
                        setInitState(10); //완료
                    });
            });
    };

    const backToSetUserName = async () => {
        await dbService
            .doc(`users/${appUser.id}`)
            .update({
                nickname: '',
            })
            .then(() => {
                setAppUser({ ...appUser, nickname: '' });
                setInitState(3);
            });
    };
    useEffect(() => {
        checkInitState();
        return () => {
            console.log('useEffect Returned');
        };
    }, [userObj, initState, appUser]);

    const onChange = (event) => {
        const { name, value } = event.target;
        if (name === 'userName') setUserName(value);
        else if (name === 'diaryName') setDiaryName(value);
    };
    const onBlur = (event) => {
        event.target.value = event.target.value.trim();
    };

    return (
        <MiddleContainer>
            {initState === 1 ? ( //인증메일발송하기
                <>
                    <LogoutButtonX />
                    <Icon
                        iconStyle="regular"
                        name="envelope"
                        style={{ fontSize: '52pt' }}
                    />
                    <VerticalSpacing height={30} />
                    <Header style={{ fontWeight: 'bolder' }}>메일 인증</Header>
                    <br />
                    <Paragraph size="big">
                        첫 로그인 후 1회 메일인증이 필요합니다.
                    </Paragraph>
                    <VerticalSpacing height={30} />

                    <Paragraph color="grey">{userObj.email}</Paragraph>
                    <br />
                    <Button color="primary" size="big" onClick={sendEmail}>
                        메일 발송
                    </Button>
                    {/* <Redirect to="/home" /> */}
                </>
            ) : initState === 2 ? ( //인증메일발송완료
                <>
                    <LogoutButtonX />
                    <Icon
                        iconStyle="regular"
                        name="envelope"
                        style={{ fontSize: '52pt' }}
                    />
                    <VerticalSpacing height={30} />
                    <Paragraph size="big">
                        고객님의 이메일 주소({userObj.email})로
                        <br />
                        <Bold>계정확인 용 인증 메일</Bold>이 발송되었습니다.
                        <VerticalSpacing height={10} />
                        인증 완료 후 본 화면으로 돌아와주세요.
                        <VerticalSpacing height={30} />
                    </Paragraph>
                    {/* <Redirect to="/home" /> */}
                </>
            ) : initState === 3 ? ( // 메일인증완료
                <animated.div style={props}>
                    <LogoutButtonX />
                    <form onSubmit={confirmUserName}>
                        <Header size="big">
                            사용할 <Bold>닉네임</Bold>을 입력해 주세요.
                        </Header>
                        <VerticalSpacing height={60} />
                        <Inputbox
                            ref={inputRef}
                            name="userName"
                            size="big"
                            placeholder="닉네임"
                            value={userName} //받아온 초깃값
                            onChange={onChange}
                            onBlur={onBlur}
                        ></Inputbox>
                        <VerticalSpacing height={40} />

                        <Button
                            color="primary"
                            size="big"
                            onClick={confirmUserName}
                        >
                            확정하기
                        </Button>
                        <input type="submit" style={{ display: 'none' }} />
                    </form>
                </animated.div>
            ) : initState === 4 ? (
                <animated.div style={props2}>
                    <LogoutButtonX />
                    <form onSubmit={confirmDiaryName}>
                        <Header size="big">
                            처음으로 생성할 <Bold>일기장</Bold>의{' '}
                            <Bold>이름</Bold>을 입력해 주세요.
                        </Header>
                        {/* <Header size="small" color="grey">
                        이름은 설정메뉴에서 변경할 수 있습니다.
                    </Header> */}
                        <VerticalSpacing height={60} />
                        <Inputbox
                            ref={inputRef}
                            name="diaryName"
                            size="big"
                            placeholder="나의 일기장"
                            value={diaryName}
                            onChange={onChange}
                            onBlur={onBlur}
                        ></Inputbox>
                        <VerticalSpacing height={40} />

                        <Button
                            color="primary"
                            size="big"
                            onClick={confirmDiaryName}
                        >
                            확정하기
                        </Button>
                        <HorizontalSpacing width={20} />
                        <Button
                            color="secondary"
                            size="big"
                            onClick={backToSetUserName}
                        >
                            뒤로가기
                        </Button>
                    </form>
                </animated.div>
            ) : initState === 10 ? (
                <Redirect to="/home" />
            ) : (
                <Redirect to="/" />
            )}
        </MiddleContainer>
    );
}

export default InitPage;

/* console.log('-------------------start----------');
            var credential = firebaseInstance.auth.EmailAuthProvider.credentialWithLink(
               userObj.email,
                'https://daylog90240.page.link/Zi7X',
            );*/
/* console.log('-------------------cre----------');
            // return;
            // Link the credential to the current user.
            authService.currentUser
                .linkWithCredential(credential)
                .then((usercred) => {
                    console.log('usercred');
                    console.log(usercred);
                    // The provider is now successfully linked.
                    // The phone user can now sign in with their phone number or email.
                })
                .catch((error) => {
                    console.error('error');
                    console.error(error);
                    // Some error occurred.
                });
            console.log('-------------------end---------');*/
