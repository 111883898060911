import React from 'react';
import { authService, firebaseInstance } from '../../fbase';
import HorizontalSpacing from './HorizontalSpacing';

function FacebookSignIn() {
    const onClick = async (authPersistence, event) => {
        const persistence = authPersistence
            ? firebaseInstance.auth.Auth.Persistence.LOCAL
            : firebaseInstance.auth.Auth.Persistence.SESSION;
        // 일단 무의미한짓임. 추후 변경 고려해보자. @@@
        await authService.setPersistence(persistence).then(async () => {
            const provider = new firebaseInstance.auth.FacebookAuthProvider();
            await authService
                .signInWithPopup(provider)
                .then((t) => {
                    console.log(t);
                })
                .catch((e) => {
                    if (
                        e.code ===
                        'auth/account-exists-with-different-credential'
                    ) {
                        alert('다른 로그인 방식으로 로그인해주세요.');
                        // if (
                        //     window.confirm(
                        //         'ID/PW로 가입된 계정입니다. 페이스북 연동을 진행하시겠습니까?',
                        //     )
                        // ) {
                        //     console.log(authService.currentUser);
                        //     authService.currentUser
                        //         .linkWithRedirect(provider)
                        //         .then(function (result) {
                        //             var credential = result.credential;
                        //             var user = result.user;
                        //             console.log(credential);
                        //             console.log(user);
                        //             // ...
                        //         })
                        //         .catch(function (error) {
                        //             console.log(error);
                        //             // Handle Errors here.
                        //             // ...
                        //         });
                        // }
                    }
                    console.log(e);
                });
        });
    };
    return (
        <div
            style={{
                cursor: 'pointer',
                width: '100%',
                backgroundColor: '#1877f2ee',
                color: 'white',
            }}
            className="button default-size full-width "
            onClick={onClick}
        >
            <span className="fa fa-facebook"></span>
            <HorizontalSpacing width={10} />
            Facebook로 계속하기
        </div>
    );
}

export default FacebookSignIn;
