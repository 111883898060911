import Icon from 'components/Atom/Icon';
import React, { useEffect, useRef, useState } from 'react';

import { dbService } from './../../fbase';
import Moveable from 'react-moveable';
import { Link } from 'react-router-dom';
import Loading from 'components/Atom/Loading';

function JournalPage({ userObj, match, setComponentLoaded }) {
    const videoRef = useRef();
    const paragraphRef = useRef();
    const [diaryName, setDiaryName] = useState('');
    const [journal, setJournal] = useState({ journalIdx: 32 });

    const [journalId, setJournalId] = useState('');
    // const [title, setTitle] = useState('');
    // const [descHTML, setDescHTML] = useState('');
    // const [descText, setDescText] = useState('');
    const [frame, setFrame] = React.useState({
        translate: [0, 0],
        rotate: 0,
        transformOrigin: '50% 50%',
    });
    const [loaded, setLoaded] = useState(false);
    const getDiaryInfo = () => {
        dbService
            .collection('journals')
            .where('ownerId', '==', userObj.uid)
            .where('diaryIdx', '==', parseInt(match.params.idx))
            .where('journalIdx', '==', parseInt(match.params.journalIdx))
            .get()
            .then((qss) => {
                if (!qss.empty) setJournal(qss.docs[0].data());

                setJournalId(qss.docs[0].id);
                dbService
                    .collection('diaries')
                    .where('ownerId', '==', userObj.uid)
                    .where('diaryIdx', '==', parseInt(match.params.idx))
                    .get()
                    .then((q) => {
                        if (!q.empty) setDiaryName(q.docs[0].data().name);
                        setLoaded(true);
                    });
                // setComponentLoaded(true);
            });
    };
    // const onChange = (event) => {
    //     if (event.target.id === 'record__title')
    //         setTitle(event.target.innerText);
    //     else if (event.target.id === 'record__desc') {
    //         setDescHTML(event.target.innerHTML);
    //         setDescText(event.target.innerText);
    //     }
    // };

    const modifyJournal = () => {
        dbService
            .collection('journals')
            .doc(journalId)
            .update({
                descHTML: paragraphRef.current.innerHTML,
                descText: paragraphRef.current.innerText,
            })
            .then((t) => {
                console.log(t);
                alert('완료~!');
            });
    };
    const onWindowResize = () => {
        console.log(videoRef.current.style.height);
        paragraphRef.current.style.height = `${
            window.innerHeight -
            220 -
            parseInt(videoRef.current.style.height, 10)
        }px`;
    };
    useEffect(() => {
        getDiaryInfo(); // 현재 다이어리 정보 세트
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);

            // setJournals([]);
            // setComponentLoaded(false);
        };
    }, []);

    return loaded ? (
        <>
            <div className="journal-page">
                <h3 className="head-title">
                    <Link to={'/diary/' + match.params.idx}>
                        <Icon
                            name="chevron-left"
                            iconStyle="solid"
                            style={{ color: 'black' }}
                        />
                        &nbsp;&nbsp; {diaryName}
                    </Link>
                </h3>
                <div className="video-container ">
                    {/* <Moveable
                        target={videoRef}
                        resizable={true}
                        keepRatio={false}
                        throttleResize={0}
                        renderDirections={[]}
                        pinchThreshold={20}
                        edge={true}
                        // className={'moveable'}
                        zoom={20}
                        origin={false}
                        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                        onResizeStart={(e) => {
                            e.setOrigin(['%', '%']);
                            e.dragStart && e.dragStart.set(frame.translate);
                        }}
                        onResize={(e) => {
                            const beforeTranslate = e.drag.beforeTranslate;

                            frame.translate = beforeTranslate;
                            e.target.style.height = `${e.height}px`;
                            e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;

                            paragraphRef.current.style.height = `${
                                window.innerHeight - 220 - e.height
                            }px`;
                            // paragraphRef.current.style.height =
                            // 800 - e.target.style.height;
                            // console.log(paragraphRef.current.style.height);
                        }}
                        onResizeEnd={(e) => {}}
                        bounds={{ left: 0, top: 0 }}
                        onRender={(e) => {
                            const { translate, rotate, transformOrigin } =
                                frame;
                            e.target.style.transformOrigin = transformOrigin;
                            e.target.style.transform =
                                `translate(${translate[0]}px, ${translate[1]}px)` +
                                ` rotate(${rotate}deg)`;
                        }}
                    /> */}

                    <video
                        ref={videoRef}
                        style={{ background: 'black', width: 900, height: 300 }}
                        src={journal.videoUrl}
                        autoplay
                        controls
                    />
                    <br />
                    <br />
                    <h3
                        className="journal__title"
                        contentEditable
                        suppressContentEditableWarning
                    >
                        {journal.title}
                    </h3>

                    <br />
                </div>
                <div
                    className="journal__description"
                    ref={paragraphRef}
                    contentEditable
                    suppressContentEditableWarning
                    dangerouslySetInnerHTML={{ __html: journal.descHTML }}
                ></div>
                <button onClick={modifyJournal}>저장</button>&nbsp;
                <button>삭제</button>
            </div>
        </>
    ) : (
        <Loading />
    );
}

export default JournalPage;
