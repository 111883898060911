import React from 'react';
import { Link } from 'react-router-dom';

function JournalItem({ journalObj }) {
    // console.log(journalObj.title);
    return (
        <Link
            to={'/diary/' + journalObj.diaryIdx + '/' + journalObj.journalIdx}
        >
            <div className="journal">
                <div
                    className="img journal__img"
                    style={{
                        background: journalObj.thumbnail
                            ? `url(${journalObj.thumbnail})`
                            : '#111',
                    }}
                ></div>
                <h4 className="journal__title">
                    {journalObj.title === '' ? '제목 없음' : journalObj.title}{' '}
                </h4>
                <p className="journal__desc">
                    {journalObj.descText === '' ? '　' : journalObj.descText}{' '}
                </p>
            </div>
        </Link>
    );
}

export default JournalItem;
