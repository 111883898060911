import React from 'react';

function MiddleContainer(props) {
    return (
        <div className="middle-container">
            <div className="middle-content">{props.children}</div>
        </div>
    );
}

export default MiddleContainer;
