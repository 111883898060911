import React from 'react';
import MiddleContainer from './../Atom/MiddleContainer';
import { animated, useSpring } from 'react-spring';
import Paragraph from './../Atom/Paragraph';
import Loading from './../Atom/Loading';
import VerticalSpacing from 'components/Atom/VerticalSpacing';

function Processing({ display }) {
    const props = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: {
            duration: 1000,
        },
    });
    return (
        <div
            className="processing-screen"
            style={{ display: display ? display : null }}
        >
            <MiddleContainer>
                <animated.div style={props}>
                    <Loading display={display} />
                    <VerticalSpacing height={200} />
                    <Paragraph size="big">처리중</Paragraph>
                </animated.div>
            </MiddleContainer>
        </div>
    );
}

export default Processing;
